<i18n src="./resources/locales.json"></i18n>

<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <span v-if="open" class="opened-section-name-color">
          {{ sectionName }}
        </span>
        <span v-else>
          {{ sectionName }}
        </span>
      </template>
    </v-expansion-panel-header>
    <v-sheet class="list-content-background-color">
      <v-expansion-panel-content
        class="list-content-background-color content-padding pb-0"
        eager
      >
        <v-container>
          <v-row class="list-content-background-color">
            <v-autocomplete
              v-model="selectedSearchedSparePart"
              :items="searchedSpareParts"
              hide-no-data
              hide-selected
              item-text="name"
              :label="$t('searchSparePart')"
              :placeholder="$t('searchSparePart')"
              prepend-inner-icon="mdi-magnify"
              return-object
              clearable
              @click:clear="clearSearchSparePart"
              :search-input.sync="sparePartToSearch"
            ></v-autocomplete>
            <SparePartWizardDetailModal @confirm-action="saveSparePart" />
          </v-row>
        </v-container>
      </v-expansion-panel-content>
      <v-expansion-panel-content
        :style="getSectionStyle()"
        class="align-self-start overflow-y-auto section-height-width list-content-background-color content-padding"
        v-scroll.self="() => {}"
        eager
      >
        <v-form ref="sparePartsSectionForm" v-model="isValid">
          <v-container>
            <v-row>
              <v-sheet class="width-section list-content-background-color">
                <v-sheet
                  v-for="sparePart in sparePartsToBeSelected"
                  :key="sparePart.id"
                  class="list-content-background-color"
                >
                  <v-row class="pb-1">
                    <v-col cols="8" class="py-1">
                      <v-checkbox
                        @change="resetValidations"
                        v-model="sparePart.isChecked"
                        hide-details
                      >
                        <template v-slot:label>
                          {{ sparePart.quantity }}x {{ sparePart.name }}
                        </template>
                      </v-checkbox>
                      <v-sheet
                        v-if="sparePart.isChecked"
                        class="list-content-background-color pl-8 pt-1"
                      >
                        <v-row v-if="sparePart.new">
                          <v-col cols="3">
                            <v-text-field
                              class="required-field"
                              v-model.number="sparePart.quantity"
                              :label="$t('quantity')"
                              :rules="[
                                quantityRules.required,
                                quantityRules.positive
                              ]"
                              required
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                              :items="
                                createListFromSparePart(
                                  sparePart.selectedUnitOfMeasure
                                )
                              "
                              :label="$t('unitsOfMeasure')"
                              :value="sparePart.selectedUnitOfMeasure"
                              v-model="sparePart.selectedUnitOfMeasure"
                              hide-details
                            ></v-select>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                              :items="sparePart.measures"
                              :label="$t('measures')"
                              v-model="sparePart.selectedMeasure"
                              hide-details
                            ></v-select>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model.number="sparePart.price"
                              :label="$t('price')"
                              :rules="[
                                value =>
                                  isPositivePriceForSparePart(
                                    sparePart,
                                    value
                                  ) ||
                                  $t('priceMustBePositiveForSelectedSparePart')
                              ]"
                              required
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="3">
                            <v-text-field
                              class="required-field"
                              v-model.number="sparePart.quantity"
                              :label="$t('quantity')"
                              :rules="[
                                quantityRules.required,
                                quantityRules.positive
                              ]"
                              required
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                              :items="sparePart.unitOfMeasures"
                              :label="$t('unitsOfMeasure')"
                              v-model="sparePart.selectedUnitOfMeasure"
                              @change="
                                assignFirstMeasureForUnitOfMeasure(
                                  $event,
                                  selectedMeasuares(
                                    sparePart.selectedUnitOfMeasure,
                                    sparePart.measures
                                  ),
                                  sparePart
                                )
                              "
                              hide-details
                            ></v-select>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                              :items="
                                selectedMeasuares(
                                  sparePart.selectedUnitOfMeasure,
                                  sparePart.measures
                                )
                              "
                              :label="$t('measures')"
                              v-model="sparePart.selectedMeasure"
                              :value="sparePart.selectedMeasure"
                            ></v-select>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model.number="sparePart.price"
                              :label="$t('price')"
                              :rules="[
                                value =>
                                  isPositivePriceForSparePart(
                                    sparePart,
                                    value
                                  ) ||
                                  $t('priceMustBePositiveForSelectedSparePart')
                              ]"
                              required
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-sheet>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-sheet>
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SparePartWizardDetailModal from '@/components/order/rectifier-order-detail-modal/sections/spare-parts-section/spare-part-wizard-detail-modal/SparePartWizardDetailModal.vue';

export default {
  name: 'SparePartsSection',

  components: {
    SparePartWizardDetailModal
  },

  props: ['sectionName'],

  data() {
    return {
      isValid: false,
      sparePartsToBeSelected: [],
      sparePartToSearch: '',
      searchedSpareParts: [],
      selectedSearchedSparePart: {}
    };
  },

  mounted() {
    this.retrieveAllSpareParts();
    this.retrieveAllUnitOfMeasures();
    this.retrieveAllMeasures();
  },

  watch: {
    sparePartToSearch: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== null) this.searchSparePart();
        if (newVal === null) this.clearSearchSparePart();
      },
      deep: true
    },

    selectedSearchedSparePart: {
      handler: function(newVal, oldVal) {
        if (!newVal) {
          return;
        }

        if (newVal !== oldVal) {
          newVal.isChecked = true;
          const foundSparePartIndex = this.sparePartsToBeSelected.findIndex(
            sp => sp.id === newVal.id
          );
          if (foundSparePartIndex >= 0) {
            this.sparePartsToBeSelected.splice(foundSparePartIndex, 1, newVal);
          }
        }
      },
      deep: true
    },

    allSpareParts: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setallSparePartsToSparePartsToBeSelected();
        }
      },
      deep: true
    },

    isValid: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('validated-spare-part-data', this.isValid);
        }
      }
    }
  },

  computed: {
    ...mapGetters('spareParts', [
      'allSpareParts',
      'allUnitOfMeasures',
      'getMeasuresByUnitOfMeasureId'
    ]),

    quantityRules() {
      return {
        required: value => !!value || this.$t('quantityIsRequired'),
        positive: value => value >= 0 || this.$t('quantityMustBePositive')
      };
    }
  },

  methods: {
    ...mapActions('spareParts', [
      'retrieveAllSpareParts',
      'retrieveAllUnitOfMeasures',
      'retrieveAllMeasures'
    ]),

    setallSparePartsToSparePartsToBeSelected() {
      this.sparePartsToBeSelected = this.allSpareParts.map(sp => ({
        ...sp,
        unitOfMeasures: this.parsedUnitOfMeasures(
          sp.unitOfMeasures,
          sp.measures
        ),
        measures: this.parsedMeasures(sp.measures),
        selectedUnitOfMeasure: this.parsedUnitOfMeasures(
          sp.unitOfMeasures,
          sp.measures
        )[0],
        selectedMeasure: this.parsedMeasures(sp.measures)[
          this.parsedUnitOfMeasures(sp.unitOfMeasures, sp.measures)[0]
        ][0],
        isChecked: false,
        received: false,
        new: false,
        quantity: 1,
        price: '',
        receivedDate: null,
        brandName: '',
        receivedBy: '',
        receivedById: ''
      }));
    },

    getUnitOfMeasureId(sp) {
      const foundUnitOfMeasureIndex = this.allUnitOfMeasures.findIndex(
        um => um.name === sp.unitOfMeasure
      );
      if (foundUnitOfMeasureIndex == -1) return 0;
      return this.allUnitOfMeasures[foundUnitOfMeasureIndex].id;
    },

    searchSparePart() {
      this.searchedSpareParts = [];
      if (!this.sparePartToSearch || this.sparePartToSearch.trim() === "") {
        return;
      }

      let sparePartToFind = this.sparePartToSearch.toLowerCase();

      this.sparePartsToBeSelected.map(item => {
        let isIncluded = item.name.toLowerCase().includes(sparePartToFind);
        if (isIncluded) this.searchedSpareParts.push(item);
      });
    },

    clearSearchSparePart() {
      this.searchedSpareParts = [];
      this.selectedSearchedSparePart = null;
      this.sparePartToSearch = "";
    },

    getSortedMeasures(measures) {
      var namedMeasures = measures.filter(name => isNaN(name));
      namedMeasures = namedMeasures.sort();

      measures = measures.filter(e => !isNaN(e));
      measures = measures.sort();
      measures = namedMeasures.concat(measures);

      return measures.map(x => x);
    },

    parsedMeasures(measures) {
      return JSON.parse(measures);
    },

    parsedUnitOfMeasures(unitOfMeasures, measures) {
      let parseUnitOfMeasures = JSON.parse(unitOfMeasures);
      parseUnitOfMeasures.forEach(
        unit =>
          (this.parsedMeasures(measures)[unit] = this.getSortedMeasures(
            this.parsedMeasures(measures)[unit]
          ))
      );
      return parseUnitOfMeasures;
    },

    saveSparePart(sparePartToSave) {
      this.sparePartsToBeSelected.unshift(Object.assign({}, sparePartToSave));
      this.$nextTick(function() {
        var root = document.getElementsByTagName('html')[0];
        root.setAttribute('class', 'overflow-y-hidden');
      });
    },

    createListFromSparePart(unitOfMeasure) {
      return [String(unitOfMeasure)];
    },

    assignFirstMeasureForUnitOfMeasure(value, items, sparePart) {
      sparePart.selectedMeasure = items[0];
    },

    selectedMeasuares(selectedUnitOfMeasure, measures) {
      return measures[selectedUnitOfMeasure];
    },

    sendSparePartsData() {
      return this.sparePartsToBeSelected.slice();
    },

    cleanSection() {
      this.resetValidations();
      this.setallSparePartsToSparePartsToBeSelected();
    },

    setSparePartsDataToEdit(spareParts) {
      spareParts.forEach(sp => {
        let indexOfSparePart = this.sparePartsToBeSelected.findIndex(
          sps => sps.name === sp.name
        );
        if (indexOfSparePart >= 0) {
          this.sparePartsToBeSelected[indexOfSparePart].isChecked = true;
          this.sparePartsToBeSelected[indexOfSparePart].selectedMeasure =
            sp.measure;
          this.sparePartsToBeSelected[indexOfSparePart].selectedUnitOfMeasure =
            sp.unitOfMeasure;
          this.sparePartsToBeSelected[indexOfSparePart].received = sp.received;
          this.sparePartsToBeSelected[indexOfSparePart].new = false;
          this.sparePartsToBeSelected[indexOfSparePart].price = sp.price;
          this.sparePartsToBeSelected[indexOfSparePart].receivedDate =
            sp.receivedDate !== undefined ? sp.receivedDate : null;
          this.sparePartsToBeSelected[indexOfSparePart].quantity = sp.quantity;
          this.sparePartsToBeSelected[indexOfSparePart].brandName =
            sp.brandName;
          this.sparePartsToBeSelected[indexOfSparePart].receivedBy = sp.receivedBy;
          this.sparePartsToBeSelected[indexOfSparePart].receivedById = sp.receivedById;
        } else {
          this.sparePartsToBeSelected.push({
            ...sp,
            measures: this.getMeasuresByUnitOfMeasureId(
              this.getUnitOfMeasureId(sp)
            ).map(m => m.name),
            selectedMeasure: sp.measure,
            selectedUnitOfMeasure: sp.unitOfMeasure,
            received: sp.received,
            new: true,
            isChecked: true,
            receivedBy: sp.receivedBy,
            receivedById: sp.receivedById
          });
        }
      });
    },

    resetValidations() {
      this.$refs.sparePartsSectionForm.resetValidation();
    },

    getSectionStyle() {
      return {
        '--window-height': window.innerHeight + 'px',
        '--window-width': window.innerWidth + 'px',
        '--sections-height': 380 + 'px'
      };
    },

    isPositivePriceForSparePart(sparePart, value) {
      if (sparePart.isChecked) {
        if (parseInt(value) < 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }
};
</script>
