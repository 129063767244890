<template>
  <div></div>
</template>

<script>
import { CLIENT_API_URL } from '../../../constants/Environment';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import axios from 'axios';

export default {
  name: 'PrintAssignedJobs',

  created() {
    this.$root.$on('print-assigned-jobs-pdf', this.printPDF);
  },

  beforeDestroy() {
    this.$root.$off('print-assigned-jobs-pdf');
  },

  methods: {
    async printPDF(startDate, endDate, orderStatus) {
      let objectToPrint = await this._fetchObjectToPrint(
        startDate,
        endDate,
        orderStatus
      );
      var opt = {
        html2canvas: { scrollY: 0 },
        filename: objectToPrint.fileName + '.pdf',
        jsPDF: {
          unit: 'in',
          orientation: 'portrait'
        }
      };

      html2pdf()
        .set(opt)
        .from(objectToPrint.htmlData)
        .outputPdf()
        .then(function(pdf) {
          printJS({
            printable: btoa(pdf),
            type: 'pdf',
            base64: true
          });
        });
    },

    async _fetchObjectToPrint(startDate, endDate, orderStatus) {
      let objectToPrintData;
      try {
        objectToPrintData = await axios.get(
          `${CLIENT_API_URL}/orders/missing-jobs-in-time-range-print?dateStart=${startDate}&dateEnd=${endDate}&orderStatus=${orderStatus}`
        );
      } catch (error) {
        console.error(
          'There was an error while retrieving the object to print' + error
        );
      }
      return objectToPrintData.data;
    }
  }
};
</script>
