<i18n src="./resources/locales.json"></i18n>

<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    max-width="600px"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title
          >{{ $t('reviewData') }}
          <span
            v-if="this.order.id === '-1'"
            class="font-weight-bold error--text"
            >- {{ nextOrderNumber }}</span
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="close(true)"> {{ $t('back') }} </v-btn>
          <v-btn
            dark
            text
            :disabled="!isValid || isSaveButtonDisabled"
            @click="openQuickVerificationModal"
          >
            {{ $t('save') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form ref="rectifierOrderResumeForm" v-model="isValid">
          <v-container>
            <p class="font-weight-bold primary--text ma-0">
              {{ $t('client') }}
              <span class="text-uppercase red--text" v-if="order.hasPriority">
                - {{ $t('priority') }}</span
              >
            </p>

            <p class="ma-0">
              <span class="font-weight-bold">{{ $t('name') }}</span>
              {{ client.name }}
            </p>
            <p class="ma-0">
              <span class="font-weight-bold">{{ $t('phone') }}</span>
              {{ client.phone }}
            </p>
            <p class="ma-0">
              <span class="font-weight-bold">{{ $t('id') }}</span>
              {{ client.ci }}
            </p>

            <p
              class="font-weight-bold primary--text ma-0"
              v-if="isNotQuickService()"
            >
              {{ $t('engine') }}
            </p>
            <p v-if="isNotQuickService()">{{ renderEgine() }}</p>

            <p class="font-weight-bold primary--text ma-0">
              {{ $t('note') }}
            </p>
            <p class="ma-0">
              {{ order.note }}
            </p>

            <v-divider v-if="isNotQuickService()"></v-divider>

            <p
              class="font-weight-bold primary--text ma-0"
              v-if="isNotQuickService()"
            >
              {{ $t('parts') }}
            </p>
            <p v-if="isNotPartsSelected() && isNotQuickService()" class="ma-0">
              {{ $t('noPartsSelected') }}
            </p>
            <ul v-for="part in partsToBeSelected" :key="part.id">
              <li v-if="part.isChecked">{{ renderCheckedPart(part) }}<br /></li>
            </ul>

            <br />

            <v-divider></v-divider>

            <p class="font-weight-bold primary--text ma-0">
              {{ $t('services') }}
            </p>
            <p v-if="isNotServicesSelected() && isNotQuickService()">
              {{ $t('noServicesSelected') }}
            </p>
            <ul v-for="service in servicesToBeSelected" :key="service.id">
              <li v-if="service.isChecked">
                {{ service.name }}<br />
                <template
                  v-if="
                    service.subServices !== null &&
                      service.subServices.some(
                        s =>
                          s.isChecked ||
                          (s.id.includes('other') && s.name !== '')
                      )
                  "
                >
                  <ul
                    v-for="subService in service.subServices"
                    :key="subService.id"
                  >
                    <li
                      v-if="
                        subService.isChecked ||
                          (subService.id.includes('other') &&
                            subService.name !== '')
                      "
                    >
                      {{ subService.name }}<br />
                      <template>
                        <span class="font-weight-bold"
                          >{{ $t('price') }}:
                        </span>
                        <span>{{ subService.price | currency('BOB') }}</span>
                      </template>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <span class="font-weight-bold">{{ $t('price') }}: </span>
                  <span>{{ service.price | currency('BOB') }}</span>
                </template>
              </li>
            </ul>

            <ul v-if="isQuickService">
              <li>
                {{ quickService.name }}
              </li>
            </ul>

            <br />

            <v-divider v-if="isNotQuickService()"></v-divider>

            <p
              class="font-weight-bold primary--text ma-0"
              v-if="isNotQuickService()"
            >
              {{ $t('spareParts') }}
            </p>

            <p v-if="isNotSparePartsSelected() && isNotQuickService()">
              {{ $t('noSparePartsSelected') }}
            </p>
            <ul v-for="sparePart in sparePartsToBeSelected" :key="sparePart.id">
              <li v-if="sparePart.isChecked">
                <span class="font-weight-bold">{{ sparePart.quantity }}x</span>
                {{ sparePart.name }} ({{ sparePart.selectedMeasure }}
                {{ sparePart.selectedUnitOfMeasure }})
                <span class="font-weight-bold" v-if="sparePart.price !== ''"
                  >{{ sparePart.price | currency('BOB') }}
                </span>
              </li>
            </ul>

            <br />

            <v-divider></v-divider>

            <p class="font-weight-bold primary--text ma-0">
              {{ $t('orderReceptionDate') }}
            </p>

            <v-row>
              <v-col cols="2">
                <DatePicker
                  :labelText="$t('receptionDate')"
                  :givenDate.sync="receptionDate"
                  :maxDate="undefined"
                  :minDate="undefined"
                  :icon="`mdi-calendar`"
                  :dateTextFieldClass="``"
                  :disabledDate="true"
                />
              </v-col>
              <v-col cols="2">
                <HourPicker
                  :labelText="$t('receptionHour')"
                  :givenHour.sync="receptionHour"
                  :icon="`mdi-clock-time-four-outline`"
                  :hourTextFieldClass="``"
                  :disabledTime="true"
                />
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <p class="font-weight-bold primary--text ma-0">
              {{ $t('estimatedDeliveryDate') }}
            </p>
            <v-row>
              <v-col cols="2">
                <DatePicker
                  :labelText="$t('deliveryDate')"
                  :givenDate.sync="deadlineDate"
                  :maxDate="undefined"
                  :minDate="minDate"
                  :icon="`mdi-calendar`"
                  :dateTextFieldClass="``"
                  :readonlyDate="false"
                />
              </v-col>
              <v-col cols="2">
                <HourPicker
                  :labelText="$t('deliveryHour')"
                  :givenHour.sync="deadlineHour"
                  :icon="`mdi-clock-time-four-outline`"
                  :hourTextFieldClass="``"
                  :readonlyTime="false"
                />
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <p class="font-weight-bold primary--text ma-0">
              {{ $t('totalToPay') }}
            </p>

            <p class="mb-0 font-weight-bold">
              {{ $t('services') }}
            </p>
            <p class="mb-0">
              <span class="deliver-option-width">
                {{ $t('totalServices') }}: </span
              ><span class="deliver-option-width">
                {{ getTotalSubService() | currency('BOB') }}
              </span>
              <span class="deliver-option-width"> {{ $t('discount') }}: </span>
              <span class="deliver-option-width">
                <v-col cols="6" class="pl-0 pt-0">
                  <v-text-field
                    v-model.number="order.servicesDiscount"
                    type="number"
                    :rules="[
                      value =>
                        isEmpty(value) || $t('discountMustBeMoreOrEqualToZero'),
                      value =>
                        isPositive(value) || $t('discountCantBeNegative'),
                      value =>
                        isLessThanTotalValue(value, getTotalSubService()) ||
                        $t('serviceDiscountCantBeMoreThatTotalServices')
                    ]"
                    required
                    suffix="Bolivianos"
                    dense
                  ></v-text-field>
                </v-col>
              </span>
              <span class="deliver-option-width"> {{ $t('balance') }} </span
              ><span class="deliver-option-width">
                {{ getTotalServiceWithDiscount() | currency('BOB') }}
              </span>
            </p>

            <br />
            <v-divider class="mx-8 mb-2"></v-divider>

            <p class="mb-0 font-weight-bold">
              {{ $t('spareParts') }}
            </p>
            <p class="mb-0">
              <span class="deliver-option-width">
                {{ $t('totalSpareParts') }}: </span
              ><span class="deliver-option-width">
                {{ getTotalSpareParts() | currency('BOB') }}
              </span>
              <span class="deliver-option-width"> {{ $t('discount') }}: </span>
              <span class="deliver-option-width">
                <v-col cols="6" class="pl-0 pt-0">
                  <v-text-field
                    v-model.number="order.detail.item.sparePartsDiscount"
                    type="number"
                    :rules="[
                      value =>
                        isEmpty(value) || $t('discountMustBeMoreOrEqualToZero'),
                      value =>
                        isPositive(value) || $t('discountCantBeNegative'),
                      value =>
                        isLessThanTotalValue(value, getTotalSpareParts()) ||
                        $t('sparePartsDiscountCantBeMoreThatTotalSpareParts')
                    ]"
                    required
                    suffix="Bolivianos"
                    dense
                  ></v-text-field> </v-col
              ></span>
              <span class="deliver-option-width"> {{ $t('balance') }} </span
              ><span class="deliver-option-width">
                {{ getTotalSparePartsWithDiscount() | currency('BOB') }}
              </span>
            </p>

            <br />
            <v-divider class="mx-8 mb-2"></v-divider>

            <p class="mb-0 font-weight-bold">
              {{ $t('totalServicesAndSpareParts') }}
            </p>
            <p class="mb-0">
              <span class="deliver-option-width"> {{ $t('total') }}: </span>
              <span class="deliver-option-width">
                {{ getTotalServicesAndSpareParts() | currency('BOB') }}
              </span>
            </p>

            <br />
            <v-divider class="mx-8 mb-2"></v-divider>

            <v-row align="center">
              <v-col>
                <p class="mb-0 font-weight-bold">
                  {{ $t('prepayment') }}
                </p>
                <span class="font-weight-bold">
                  {{ $t('paymentMethod') }}:
                </span>
                <v-radio-group v-model="paymentMethod" dense row class="mt-0">
                  <v-radio :label="$t('cash')" :value="'cash'"></v-radio>
                  <v-radio :label="$t('card')" :value="'card'"></v-radio>
                  <v-radio
                    :label="$t('transfer')"
                    :value="'transfer'"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-divider class="mx-8 mb-2"></v-divider>

            <v-row dense>
              <v-col cols="3">
                <v-text-field
                  v-model.number="onAccount"
                  type="number"
                  :rules="[
                    value =>
                      isEmpty(value) || $t('onAccountMustBeMoreOrEqualToZero'),
                    value => isPositive(value) || $t('onAccountCantBeNegative'),
                    value =>
                      isLessThanTotalValue(
                        value,
                        getTotalServicesAndSpareParts()
                      ) ||
                      $t('onAccountCantBeMoreThatTotalServicesAndSpareParts')
                  ]"
                  required
                  suffix="Bolivianos"
                  dense
                  class="mt-4"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="mx-8 mb-2"></v-divider>

            <p class="mb-0 font-weight-bold">
              {{ $t('finalRemaining') }}
            </p>
            <p>
              <span class="deliver-option-width">
                {{ $t('finalRemaining') }}:
              </span>
              <span class="deliver-option-width">
                {{ calculateRestOfPayment() | currency('BOB') }}
              </span>
            </p>

            <v-divider></v-divider>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <VerificationModal ref="verificationModal" @confirm-action="signAndSave" />
    <ErrorMessageModal
      ref="errorMessageModal"
      @back-to-rectifier-order-detail="close(true)"
    />
    <InfoMessageModal ref="infoMessageModal" />
  </v-dialog>
</template>

<script>
import {
  BUSINESS_ID_RECTIFICATION,
  DEFAULT_CURRENCY,
  EMPTY_GUID
} from '@/constants/ConfigurationConstants';
import ErrorMessageModal from '@/components/reusable-components/error-message-modal/ErrorMessageModal.vue';
import DatePicker from '@/components/reusable-components/date-picker/DatePicker.vue';
import HourPicker from '@/components/reusable-components/hour-picker/HourPicker.vue';
import InfoMessageModal from '@/components/reusable-components/info-message-modal/InfoMessageModal.vue';
import VerificationModal from '@/components/reusable-components/verification-modal/VerificationModal.vue';
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'RectifierOrderResumeModal',

  components: {
    ErrorMessageModal,
    DatePicker,
    HourPicker,
    InfoMessageModal,
    VerificationModal
  },

  data: () => ({
    dialog: false,
    isValid: false,
    isSaveButtonDisabled: false,
    datePickerMenu: false,
    hourPickerMenu: false,
    deadlineDate: null,
    deadlineHour: null,
    receptionDate: moment().format('YYYY-MM-DD'),
    receptionHour: moment().format('HH:mm'),
    order: {
      id: '-1',
      client: {
        id: '-1',
        name: '',
        phone: null,
        address: '',
        ci: null
      },
      detail: {
        item: {
          specification: {
            engine: '',
            brand: '',
            numberOfCylinders: ''
          },
          parts: [],
          spareParts: [],
          sparePartsDiscount: 0
        },
        services: []
      },
      currencyCode: DEFAULT_CURRENCY,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: null,
      creationDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
      estimatedDeliverDate: null,
      hasPriority: false,
      servicesDiscount: 0,
      receivedBy: '',
      receivedById: '',
      note: ''
    },
    defaultOrder: {
      id: '-1',
      client: {
        id: '-1',
        name: '',
        phone: null,
        address: '',
        ci: null
      },
      detail: {
        item: {
          specification: {
            engine: '',
            brand: '',
            numberOfCylinders: ''
          },
          parts: [],
          spareParts: [],
          sparePartsDiscount: 0
        },
        services: []
      },
      currencyCode: DEFAULT_CURRENCY,
      businessId: BUSINESS_ID_RECTIFICATION,
      orderStatusId: null,
      creationDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
      estimatedDeliverDate: null,
      hasPriority: false,
      servicesDiscount: 0,
      receivedBy: '',
      receivedById: '',
      note: ''
    },
    client: {
      id: EMPTY_GUID,
      name: '',
      phone: null,
      address: '',
      ci: null
    },
    specification: {
      engine: '',
      brand: '',
      numberOfCylinders: ''
    },
    partsToBeSelected: [],
    servicesToBeSelected: [],
    sparePartsToBeSelected: [],
    minDate: moment().format('YYYY-MM-DD'),
    quickService: {
      id: 'quickService',
      isOther: true,
      name: '',
      price: null,
      subServices: [],
      currencyCode: 'BOB',
      quantity: 1,
      isFinished: false
    },
    isQuickService: false,
    defaultClient: {
      id: EMPTY_GUID,
      name: '',
      phone: null,
      address: '',
      ci: null
    },
    defaultSpecification: {
      engine: '',
      brand: '',
      numberOfCylinders: ''
    },
    defaultQuickService: {
      id: 'quickService',
      isOther: true,
      name: '',
      price: null,
      subServices: [],
      currencyCode: 'BOB',
      quantity: 1,
      isFinished: false
    },
    errorMessage: '',
    nextOrderNumber: null,
    isDebitCard: false,
    paymentMethod: '',
    onAccount: 0,
    defaultQuickValidationResponse: {
      response: false,
      id: '00000000-0000-0000-0000-000000000000',
      fullName: ''
    }
  }),

  computed: {
    ...mapGetters('orderStatuses', ['getInitialOrderStatus']),
    ...mapGetters('brands', ['allBrands']),
    ...mapGetters('userStorage', ['getQuickValidationResponse'])
  },

  watch: {
    paymentMethod(newValue) {
      this.isDebitCard = newValue === 'card';
    }
  },

  methods: {
    ...mapActions('orders', ['addOrder', 'updateOrder', 'makePayment']),
    ...mapActions('brands', ['addBrand']),
    ...mapMutations('userStorage', ['setQuickValidationResponse']),

    open(
      orderToSave,
      client,
      specification,
      parts,
      services,
      spareParts,
      isQuickService,
      quickService,
      nextOrderNumber,
      onAccount,
      isDebitCard,
      paymentMethod
    ) {
      this.order = Object.assign({}, orderToSave || this.defaultOrder);
      this.client = client;
      this.receptionDate = moment(this.order.creationDate).format('YYYY-MM-DD');
      this.receptionHour = moment(this.order.creationDate).format('HH:mm');
      if (this.order.estimatedDeliverDate) {
        this.deadlineDate = moment(this.order.estimatedDeliverDate).format(
          'YYYY-MM-DD'
        );
        this.deadlineHour = moment(this.order.estimatedDeliverDate).format(
          'HH:mm'
        );
      }
      this.quickService = Object.assign({}, quickService);
      this.isQuickService = isQuickService;
      if (
        this.order.servicesDiscount === undefined ||
        this.order.servicesDiscount === ''
      )
        this.order.servicesDiscount = 0;
      this.onAccount =
        onAccount === undefined || onAccount === '' ? 0 : onAccount;
      this.isDebitCard = isDebitCard;
      this.paymentMethod = paymentMethod;
      if (isQuickService) {
        this.partsToBeSelected = [];
        this.servicesToBeSelected = [];
        this.sparePartsToBeSelected = [];
        this.order.detail.item.specification = Object.assign({}, specification);
        this.specification = specification;
      } else {
        this.partsToBeSelected = parts;
        this.servicesToBeSelected = services;
        this.sparePartsToBeSelected = spareParts;
        this.specification = specification;
        if (
          this.order.detail.item.sparePartsDiscount === undefined ||
          this.order.detail.item.sparePartsDiscount === ''
        )
          this.order.detail.item.sparePartsDiscount = 0;
      }
      this.nextOrderNumber = this.orderNumberFormatter(nextOrderNumber);
      this.dialog = true;
    },

    close(isBack) {
      this.resetValidations();
      if (isBack) {
        this.setEmptyValuesToZero();
        this.$root.$emit(
          'set-data-from-resume-modal',
          Object.assign(
            {},
            {
              deadlineDate: this.deadlineDate,
              deadlineHour: this.deadlineHour,
              onAccount: this.onAccount,
              servicesDiscount: this.order.servicesDiscount,
              isDebitCard: this.isDebitCard,
              paymentMethod: this.paymentMethod
            }
          )
        );
      }
      this.clearResumeModal();
      this.dialog = false;
      if (isBack) {
        this.$nextTick(function() {
          var root = document.getElementsByTagName('html')[0];
          root.setAttribute('class', 'overflow-y-hidden');
        });
      }
    },

    clearResumeModal() {
      this.order = Object.assign({}, this.defaultOrder);
      this.deadlineDate = null;
      this.deadlineHour = null;
      this.receptionDate = moment().format('YYYY-MM-DD');
      this.receptionHour = moment().format('HH:mm');
      this.client = Object.assign({}, this.defaultClient);
      this.especification = Object.assign({}, this.defaultSpecification);
      this.partsToBeSelected = [];
      this.servicesToBeSelected = [];
      this.sparePartsToBeSelected = [];
      this.order.detail.item.sparePartsDiscount = 0;
      this.quickService = Object.assign({}, this.defaultQuickService);
      this.isQuickService = false;
      this.errorMessage = '';
      this.isDebitCard = false;
      this.paymentMethod = '';
      this.onAccount = 0;
      this.isSaveButtonDisabled = false;
    },

    renderEgine() {
      let result = '';
      const { engine, brand, numberOfCylinders } = this.specification;
      if (engine !== '') result += ` ${engine},`;
      if (brand !== '') result += ` ${brand},`;
      if (numberOfCylinders !== '') result += ` ${numberOfCylinders}`;

      return result;
    },

    isNotPartsSelected() {
      return this.partsToBeSelected.every(p => p.isChecked === false);
    },

    isNotServicesSelected() {
      return this.servicesToBeSelected.every(s => s.isChecked === false);
    },

    isNotSparePartsSelected() {
      return this.sparePartsToBeSelected.every(sp => sp.isChecked === false);
    },

    renderCheckedPart(part) {
      if (part.isChecked) {
        if (
          part &&
          part.subParts.some(p => p.isChecked || (p.id === '' && p.name !== ''))
        ) {
          return `${part.name} (${this._renderCheckedSubParts(part)}) `;
        } else return ` ${part.name} `;
      }
    },

    _renderCheckedSubParts(part) {
      let subParts = part.subParts.filter(
        subPart =>
          subPart.isChecked || (subPart.id === '' && subPart.name !== '')
      );
      return subParts.map(subPart => subPart.name).join(', ');
    },

    renderCheckedService(service) {
      if (service.isChecked) {
        if (
          service &&
          service.subServices !== null &&
          service.subServices.some(
            s => s.isChecked || (s.id.includes('other') && s.name !== '')
          )
        )
          return `${service.name} (${this._renderCheckedSubServices(
            service
          )}) `;
        else return ` ${service.name} `;
      }
    },

    _renderCheckedSubServices(service) {
      let subServices = service.subServices.filter(
        subservice =>
          subservice.isChecked ||
          (subservice.id.includes('other') && subservice.name !== '')
      );
      return subServices.map(subservice => subservice.name).join(', ');
    },

    getTotalSubService() {
      let total = 0;
      if (this.isQuickService) {
        total = this.quickService.price;
      } else {
        this.servicesToBeSelected.forEach(s => {
          if (s.isChecked) {
            if (s.subServices !== null) {
              if (s.subServices.length === 1) total += Number(s.price);
              s.subServices.forEach(ss => {
                if (ss.isChecked || (ss.id.includes('other') && ss.name !== ''))
                  total += Number(ss.price);
              });
            }
          }
        });
      }
      return total;
    },

    getTotalServiceWithDiscount() {
      return this.getTotalSubService() - this.order.servicesDiscount;
    },

    assignPartsToOrder() {
      this.order.detail.item.parts = this.partsToBeSelected;

      this.order.detail.item.parts = this.order.detail.item.parts.filter(
        p => p.isChecked === true
      );

      this.order.detail.item.parts.forEach(
        p =>
          (p.subParts = p.subParts.filter(
            sp => sp.isChecked || (sp.id === '' && sp.name !== '')
          ))
      );

      this.order.detail.item.parts.forEach(p => {
        delete p.isChecked;
        delete p.index;
      });

      this.order.detail.item.parts.forEach(p =>
        p.subParts.forEach(sp => delete sp.isChecked)
      );
    },

    assignServicesToOrder() {
      if (this.isQuickService) {
        this.order.detail.services = [];
        this.order.detail.services.push(this.quickService);
      } else {
        this.order.detail.services = this.servicesToBeSelected;

        this.order.detail.services = this.order.detail.services.filter(
          s => s.isChecked === true
        );

        this.order.detail.services.forEach(s => {
          if (s.subServices !== null) {
            s.subServices = s.subServices.filter(
              ss => ss.isChecked || (ss.id.includes('other') && ss.name !== '')
            );
          }
        });

        this.order.detail.services.forEach(s => {
          delete s.fixedPrice;
          delete s.fixedQuantity;
          delete s.index;
          delete s.parts;
          delete s.isChecked;
        });

        this.order.detail.services.forEach(s => {
          if (s.subServices !== null) {
            s.subServices.forEach(ss => {
              delete ss.currencyCode;
              delete ss.fixedPrice;
              delete ss.fixedQuantity;
              delete ss.serviceId;
              delete ss.index;
              delete ss.isChecked;
            });
          }
        });
      }
    },

    assignSparePartsToOrder() {
      this.order.detail.item.spareParts = this.sparePartsToBeSelected.filter(
        sp => sp.isChecked === true
      );

      this.order.detail.item.spareParts = this.order.detail.item.spareParts.map(
        sparePart => {
          return {
            name: sparePart.name,
            measure: sparePart.selectedMeasure,
            unitOfMeasure: sparePart.selectedUnitOfMeasure,
            received: sparePart.received,
            new: sparePart.new,
            quantity: sparePart.quantity,
            parts: sparePart.parts,
            price: sparePart.price === '' ? '' : parseInt(sparePart.price),
            receivedDate: sparePart.receivedDate,
            brandName: sparePart.brandName,
            receivedBy: sparePart.receivedBy,
            receivedById: sparePart.receivedById
          };
        }
      );
    },

    setInitialOrderStatusOnOrder() {
      this.order.orderStatusId = this.getInitialOrderStatus.id;
    },

    setEstimatedDeliverDateToOrder() {
      if (this.deadlineDate !== null || this.deadlineHour !== null) {
        if (this.deadlineDate === null) {
          this.deadlineDate = moment().format('YYYY-MM-DD');
        }
        if (this.deadlineHour === null) {
          this.deadlineHour = moment().format('HH:mm');
        }
        let concatedDeadline = moment(
          this.deadlineDate + this.deadlineHour,
          'YYYY-MM-DDLT'
        );
        this.order.estimatedDeliverDate = moment(concatedDeadline).format(
          'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
        );
      }
    },

    createNewBrand() {
      if (this.order.detail.item.specification.brand) {
        let brands = this.allBrands.map(brand => {
          return brand.name.toLowerCase();
        });
        if (
          !brands.includes(
            this.order.detail.item.specification.brand.toLowerCase()
          )
        ) {
          this.addBrand({ name: this.order.detail.item.specification.brand });
        }
      }
    },

    openQuickVerificationModal() {
      if (this.order.id === '-1') {
        this.$refs.verificationModal.open();
      } else {
        this.save();
      }
    },

    signAndSave() {
      this.order.receivedBy = this.getQuickValidationResponse.fullName;
      this.order.receivedById = this.getQuickValidationResponse.id;
      this.setQuickValidationResponse(this.defaultQuickValidationResponse);
      this.save();
    },

    async save() {
      if (this.isNotQuickService()) {
        this.validatePartsData();
        this.validateServicesData();
        this.validateServicesPricesData();
      }
      if (this.errorMessage === '') {
        this.setEmptyValuesToZero();
        this.assignClientToOrder();
        this.assignSpecificationToOrder();
        this.assignPartsToOrder();
        this.assignServicesToOrder();
        this.assignSparePartsToOrder();
        this.setEstimatedDeliverDateToOrder();
        this.createNewBrand();
        this.checkAssignedServicesData();
        if (this.order.id === '-1') {
          try {
            this.isSaveButtonDisabled = true;
            this.setInitialOrderStatusOnOrder();
            this.order.creationDate = moment().format(
              'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
            );
            let res = await this.addOrder({
              orderToAdd: Object.assign({}, this.order),
              amount: this.onAccount,
              isDebitCard: this.isDebitCard,
              paymentMethod: this.paymentMethod
            });
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: `${this.$t('orderWasCreatedSuccessfully')} ${
                    res.data.orderNumber
                  }`,
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileCreatingTheOrder'),
                  messageType: 'error'
                }
              )
            );
          }
        } else {
          try {
            this.isSaveButtonDisabled = true;
            await this.updateOrder(Object.assign({}, this.order));
            if (this.onAccount > 0) {
              let pay = {
                orderId: this.order.id,
                date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
                orderStatusId: this.order.orderStatusId,
                amount: parseInt(this.onAccount),
                isDebitCard: this.isDebitCard,
                paymentMethod: this.paymentMethod
              };
              await this.makePayment({
                order: Object.assign({}, this.order),
                pay
              });
            }
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('orderInfoWasUpdatedSuccessfully'),
                  messageType: 'success'
                }
              )
            );
          } catch (error) {
            this.$root.$emit(
              'open-status-message-snackbar',
              Object.assign(
                {},
                {
                  message: this.$t('errorWhileUpdatingTheOrder'),
                  messageType: 'error'
                }
              )
            );
          }
        }
        this.close(false);
        this.$emit('confirm-action');
      } else {
        let message = this.$t('pleaseCheckThatYourOrder');
        let concatedMessage = message.concat(': ', this.errorMessage);

        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: concatedMessage,
              messageType: 'error'
            }
          )
        );
      }
    },

    assignClientToOrder() {
      this.order.client = Object.assign({}, this.client);
    },

    assignSpecificationToOrder() {
      this.order.detail.item.specification = Object.assign(
        {},
        this.specification
      );
    },

    isNotQuickService() {
      return !this.isQuickService;
    },

    areSomePartsSelected() {
      return this.partsToBeSelected.some(part => part.isChecked === true);
    },

    areSomeServicesSelected() {
      return this.servicesToBeSelected.some(
        service => service.isChecked === true
      );
    },

    areSomeSubServicesSelected(subServices) {
      return subServices.some(service => service.isChecked === true);
    },

    isOtherSubServiceSelected(subServices) {
      return subServices.some(
        service => service.id.includes('other') && service.name !== ''
      );
    },

    validatePartsData() {
      if (!this.areSomePartsSelected()) {
        this.buildErrorMessage(this.$t('atLeastAPartIsSelected'));
      }
    },

    validateServicesData() {
      if (!this.areSomeServicesSelected()) {
        this.buildErrorMessage(this.$t('atLeastAServiceIsSelected'));
      } else {
        let existSubServices = true;
        let checkedServices = this.servicesToBeSelected.filter(
          service => service.isChecked === true
        );
        checkedServices.forEach(service => {
          if (service.subServices.length !== 1) {
            if (!this.areSomeSubServicesSelected(service.subServices)) {
              if (!this.isOtherSubServiceSelected(service.subServices)) {
                existSubServices = false;
              }
            }
          }
        });
        if (!existSubServices) {
          this.buildErrorMessage(
            this.$t('atLeastASubServiceFromSelectedServicesIsSelected')
          );
        }
      }
    },

    validateServicesPricesData() {
      if (this.areSomeServicesSelected()) {
        let checkedServices = this.servicesToBeSelected.filter(
          service => service.isChecked === true
        );
        checkedServices.forEach(service => {
          if (service.subServices.length !== 1) {
            const subServices = [...service.subServices];
            let existCheckedPrices = true;
            let existNegativePrices = false;
            subServices.forEach(subService => {
              if (
                !subService.id.includes('other') &&
                subService.price === null &&
                subService.isChecked
              ) {
                existCheckedPrices = false;
              }
              if (
                !subService.id.includes('other') &&
                subService.price < 0 &&
                subService.isChecked
              ) {
                existNegativePrices = true;
              }
              if (
                subService.id.includes('other') &&
                subService.name !== '' &&
                subService.price === null
              ) {
                existCheckedPrices = false;
              }
              if (
                subService.id.includes('other') &&
                subService.name !== '' &&
                subService.price < 0
              ) {
                existNegativePrices = true;
              }
            });
            if (!existCheckedPrices || existNegativePrices) {
              this.buildErrorMessage(
                this.$t('selectedSubServicesHaveAPriceAndPriceMustBePositive')
              );
            }
          } else {
            if (service.price === null) {
              this.buildErrorMessage(this.$t('selectedServicesHaveAPrice'));
            } else {
              if (service.price < 0) {
                this.buildErrorMessage(
                  this.$t('selectedServicesHavePositivePrice')
                );
              }
            }
          }
        });
      }
    },

    checkAssignedServicesData() {
      if (this.order.assignedServices !== undefined) {
        const { assignedServices } = this.order;
        let newAssignedServices = assignedServices.slice();
        if (assignedServices.length > 0) {
          assignedServices.forEach(assigned => {
            let foundService;
            if (assigned.subServiceId !== null) {
              foundService = this.order.detail.services.find(
                service => service.id === assigned.serviceId
              );
              if (foundService !== undefined) {
                foundService = foundService.subServices.find(
                  subService => subService.id === assigned.subServiceId
                );
                if (foundService === undefined) {
                  let foundServiceIndex = newAssignedServices.findIndex(
                    newAssigned =>
                      JSON.stringify(newAssigned) === JSON.stringify(assigned)
                  );
                  newAssignedServices.splice(foundServiceIndex, 1);
                }
              }
            } else {
              foundService = this.order.detail.services.find(
                service => service.id === assigned.serviceId
              );
              if (foundService === undefined) {
                let foundServiceIndex = newAssignedServices.findIndex(
                  newAssigned =>
                    JSON.stringify(newAssigned) === JSON.stringify(assigned)
                );
                newAssignedServices.splice(foundServiceIndex, 1);
              }
            }
          });
          this.order.assignedServices = newAssignedServices;
        }
      }
    },

    buildErrorMessage(message) {
      if (this.errorMessage === '') {
        this.errorMessage = message;
      }
    },

    orderNumberFormatter(orderNumber) {
      return orderNumber.toString().length < 5
        ? this.orderNumberFormatter('0' + orderNumber)
        : orderNumber;
    },

    resetValidations() {
      this.$refs.rectifierOrderResumeForm.resetValidation();
    },

    isEmpty(value) {
      return value !== '';
    },

    isPositive(value) {
      return value >= 0;
    },

    isLessThanTotalValue(input, total) {
      return input <= total;
    },

    getTotalSpareParts() {
      let total = 0;
      this.sparePartsToBeSelected.forEach(sp => {
        if (sp.isChecked) total += Number(sp.price);
      });
      return total;
    },

    getTotalSparePartsWithDiscount() {
      let discount = this.getSparePartsDiscount();
      return this.getTotalSpareParts() - discount;
    },

    getTotalServicesAndSpareParts() {
      let restOfPayment =
        this.getTotalSparePartsWithDiscount() +
        this.getTotalServiceWithDiscount();
      return parseInt(restOfPayment).toFixed(2);
    },

    getSparePartsDiscount() {
      const { detail } = this.order;
      if (detail) {
        const { item } = detail;
        const { sparePartsDiscount } = item;
        if (sparePartsDiscount) return parseInt(sparePartsDiscount).toFixed(2);
        else return 0;
      } else return 0;
    },

    calculateRestOfPayment() {
      let restOfPayment = this.getTotalServicesAndSpareParts() - this.onAccount;
      return parseInt(restOfPayment).toFixed(2);
    },

    setOnAccountToZero() {
      if (this.onAccount === '') this.onAccount = 0;
    },

    setServicesDiscountToZero() {
      if (this.order.servicesDiscount === '') this.order.servicesDiscount = 0;
    },

    setSparePartDiscountToZero() {
      if (this.order.detail.item.sparePartsDiscount === '')
        this.order.detail.item.sparePartsDiscount = 0;
    },

    setEmptyValuesToZero() {
      this.setOnAccountToZero();
      this.setServicesDiscountToZero();
      this.setSparePartDiscountToZero();
    }
  }
};
</script>
