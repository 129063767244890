import { render, staticRenderFns } from "./AssignedJobs.vue?vue&type=template&id=35d8749a&scoped=true"
import script from "./AssignedJobs.vue?vue&type=script&lang=js"
export * from "./AssignedJobs.vue?vue&type=script&lang=js"
import style0 from "./AssignedJobs.vue?vue&type=style&index=0&id=35d8749a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d8749a",
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fviews%2Fassigned-jobs%2FAssignedJobs.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports